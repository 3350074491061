@import url('https://fonts.googleapis.com/css2?family=Koulen&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		@apply dark:bg-sv-white bg-sv-dark;
	}

	* {
		@apply leading-tight;
	}

	.corners {
		@apply rounded-md;
	}

	.corners-2 {
		@apply rounded-sm;
	}

	.corners-3 {
		@apply rounded-xl;
	}

	.corners-left-3 {
		@apply rounded-l-xl rounded-r-sm;
	}

	.corners-right-3 {
		@apply rounded-r-xl rounded-l-sm;
	}

	.scrollbar::-webkit-scrollbar-track {
		/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
		border-radius: 10px;
		/* background-color: #F5F5F5; */
		background: transparent;
		position: absolute;
	}

	.scrollbar::-webkit-scrollbar {
		height: 8px;
		/* background-color: #F5F5F5; */
		background: transparent;
	}

	.scrollbar::-webkit-scrollbar-thumb {
		border-radius: 10px;
		/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
		/* background-color: #D62929; */
		@apply dark:bg-sv-white bg-sv-black;
	}
}

* {
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* select {
	appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
} */

option {
	font-family: 'Poppins', sans-serif;
}

*:focus {
	outline: none !important;
}

#root,
#widgetsInterface,
#toolBarInterface,
#notificationsInterface,
#menuInterface {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	overflow: hidden;
}

#menuInterface {
	z-index: 1000;
}

#notificationsInterface {
	z-index: 1100;
}

.video-background iframe.title {
    top: -30px !important; /* Hide the video title */
}

@media (min-aspect-ratio: 16/9) {
	.video-background{
		height: 300%;
		top: -100%;
	}
}

@media (max-aspect-ratio: 16/9) {
	.video-background iframe {
		width: 177.78vh !important;
		height: 120% !important;
		/* add padding bottom */
		top: -19.08%;
		/* center it */
		/* left: -38.89% !important; */
	}
}

@media all and (display-mode: fullscreen) {
	.video-background {
		height: 100%;
		top: 0;
	}

	/* fix the full screen the left very left */
	.video-background iframe {
		height: 120% !important;
		left: 0 !important;
		top: -10% !important;
	}
}